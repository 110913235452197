/* eslint-disable no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useRef, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import AuthService from '../../services/authServices';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Link, useNavigate } from 'react-router-dom';
import AnimatedSuccessGIF from "../../assets/images/success.gif";
import { Message } from 'primereact/message';

import { ProgressBar } from 'primereact/progressbar';

import ReactCountryFlag from "react-country-flag";
import Select from "react-select";
import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { fontSize } from '@mui/system';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TransactionService from '../../services/transactionServices';
import logoLight from "../../assets/images/wiremit-facial-recognition.png";
import { Dialog } from 'primereact/dialog';
import TagManager from 'react-gtm-module'
import LoadingButton from "@mui/lab/LoadingButton";
import { BlockUI } from 'primereact/blockui';
const axios = require('axios');

const tagManagerArgs = {
  dataLayer: {
    userId: '001',
    userProject: 'wiremit-international',
    page: 'sign up'
  }
}

AOS.init({
  duration: 1800,
});

const RegisterCompleteProfile = () => {

  TagManager.dataLayer(tagManagerArgs);

  const [kycRequirementsDialog, setKycRequirementsDialog] = useState(false);
  const [checkVerificationStatus, setCheckVerificationStatus] = useState(false);
  const [personaConfirmation, setPersonaConfirmationDialog] = useState(false);

  const optionsCountries = countryList().getData();
  const currentDate = new Date();
  const nextWeek = new Date(currentDate);
  const [liveVerifyUrl, setLiveVerifyUrl] = useState("");
  const [displayLiveVerifyIframe, setLiveVerifyIframe] = useState(false);

  const [errorMsg, setErrorMessage] = useState("");


  /* temp mobile fix for full address ui element */
  const [isMobile, setIsMobile] = useState(false);

  const checkMobile = () => {
    if (window.innerWidth < 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  /* temp mobile fix for full address ui element */



  nextWeek.setDate(currentDate.getDate() + 36500);
  const id1ExpiryDefault = nextWeek.toISOString().split('T')[0]; // Output in "YYYY-MM-DD" format

  const Ref = useRef(null);

  const options = [
    { value: 'NA', label: 'Rather not say' },
    { value: 'F', label: 'FEMALE' },
    { value: 'M', label: 'MALE' },
    { value: 'O', label: 'Other' },
  ];

  const idTypeOptions = [
    { value: 'national_id', label: 'National ID' },
    { value: 'driving_license', label: 'Driving license' },
    { value: 'passport', label: 'Passport' }
  ];

  const [genderData, setgenderData] = useState({
    optionGenders: options,
    genderVal: null
  });

  const [idTypeData, setIdTypeData] = useState({
    idTypeOptions: idTypeOptions,
    idTypeVal: idTypeOptions[0]
  });

  const [countryData, setCountryData] = useState({
    optionsCountries: optionsCountries,
    country: "",
    region: "",
    countryVal: null
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    mobile: '',
    address1: '',
    postcode: '',
    occupation: '',
    password: '',
    verifyPassword: '',
    mobile: ''
  });

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    nationality: "",
    gender: "",
    mobile: "",
    dob: "",
    placeOfBirth: "",
    countryOfBirth: "",
    password: "",
    verifyPassword: "",
    usernameType: "email",
    toc: "true",
    sourceCountryId: "01",
    registrationType: "registered",
    postcode: "",
    address1: "",
    id1Type: "",
    id1Details: "",
    occupation: "",
    id1Expiry: "", //id1ExpiryDefault.toString()
    mobile: "",
  });

  const [value, setValue] = useState(0);
  const [verificationLoader, setWaitVerificationLoader] = useState(false);
  const [doneButtonLabel, setDoneButtonLabel] = useState("DONE");

  const [showAddressDropdown, setShowAddressses] = useState(true);

  const [addressData, setAddressData] = useState({
    addressOptions: [],
    addressVal: null
  });


  const RegistrationForm = () => {
    return (<></>)
  }

  /*
    useEffect(() => {
  // TODO dodgey logic to log us out
      const fetchRates = async () => {
        const response = await TransactionService.getRates('Zimbabwe', 'GBP', 'USD', 1, 'SOURCE');
        if (!response?.body?.result?.rate) {
          //session is dead most likely
  
          localStorage.removeItem('sessionToken');
          localStorage.removeItem('ratesSessionToken');
          localStorage.removeItem('username');
          localStorage.removeItem('user');
  
          navigate('/');
        }
      };
  
      fetchRates().then()
    }, []); */

  const LoadingSkeleton = () => {
    return (
      <div className="border-round border-1 surface-border p-4 surface-card">
        <div className="flex mb-3">
          <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
          <div>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height=".5rem"></Skeleton>
          </div>
        </div>
        <Skeleton width="100%" height="150px"></Skeleton>
      </div>)
  }

  const viewList = [
    <RegistrationForm />,
    <LoadingSkeleton />,
  ]
  const [displayProgress, setDisplayProgress] = useState(0);

  const navigate = useNavigate();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [verificationLeg, setverificationLeg] = useState(false);
  const [emailVerificationCode, setemailVerificationCode] = useState("");
  const [loginFormData, setLoginFormData] = useState({
    username: "",
    password: "",
  });

  const [timer, setTimer] = useState("00");
  const interval = useRef(null);

  const getTimeRemaining = (e) => {
    const total =
      Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor(
      (total / 1000 / 60) % 60
    );
    const hours = Math.floor(
      (total / 1000 / 60 / 60) % 24
    );
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } =
      getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        // (hours > 9 ? hours : "0" + hours) +
        // ":" +
        // (minutes > 9
        //   ? minutes
        //   : "0" + minutes) +
        // ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {

    setTimer("10");

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 10);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
    checkMobile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const validateForm = () => {
    let valid = true;
    const errors = {};

    { formErrors.email && <div>{formErrors.email}</div> }
    { formErrors.mobile && <div>{formErrors.mobile}</div> }
    { formErrors.occupation && <div>{formErrors.occupation}</div> }
    { formErrors.address1 && <div>{formErrors.address1}</div> }
    { formErrors.postcode && <div>{formErrors.postcode}</div> }
    { formErrors.password && <div>{formErrors.password}</div> }
    { formErrors.verifyPassword && <div>{formErrors.verifyPassword}</div> }





    // Validate First Name
    if (!formData.occupation.trim()) {
      errors.occupation = 'Occupation is required';
      valid = false;
    }

    // Validate First Name
    if (!formData.address1.trim()) {
      errors.address1 = 'Address is required';
      valid = false;
    }

    // Validate First Name
    if (!formData.postcode.trim()) {
      errors.postcode = 'Postcode is required';
      valid = false;
    }


    // Validate First Name
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
      valid = false;
    }

    // Set form errors
    setFormErrors(errors);

    return valid;
  };


  const handleSubmitCompleteProfile = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    setKycRequirementsDialog(true);
    setLoading(true);
    const { passwordValidation, verifyPasswordValidation, ...formDataWithoutPasswordValidation } = formData;

    try {

      setLoading(false);

      const veriffResponse = await AuthService.getRegistrationVerificationUUID();
      console.log(veriffResponse);
      const liveIdUrl = veriffResponse.body.live_id_service_provider_details.verification_url;
      setLiveVerifyUrl(liveIdUrl);
      setLiveVerifyIframe(true);
      window.open(liveIdUrl, '_blank');
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Registration Failed',
        detail: "Profile processing failed. Please try again in a moment.",
        life: 4000
      })
      window.fbq('track', 'Signup/Createprofile', {
        status: "Failed",
        detail: "Profile processing failed. Please try again in a moment."
      });
      console.error('Error:', error);
    }
  };


  const confirmPersonaDetails = async (e) => {
    e.preventDefault();

    var uuid = localStorage.getItem("uuid");
    formData.uuid = uuid;
    formData.sessionToken = localStorage.getItem("sessionToken");
    //call register -> api to register person sending the formData

    try {
      const response = await AuthService.updateProfile(formData);
      if (response.status === "SUCCESS") {
        //console.debug('Registration successful');

        window.fbq('track', 'Signup/Createprofile', {
          status: "Complete",
          detail: ""
        });

        setLoading(false);
        if (response.status === 'SUCCESS') {

          toast.current.show({
            severity: 'success',
            summary: 'Registration Success',
            detail: response.message,
            life: 4000
          })

          setLoading(true);

          AuthService.getProfile({ username: localStorage.getItem("username") }, { sessionToken: [localStorage.getItem("sessionToken")], status: "SUCCESS" }).then(
            () => {
              navigate('/dashboard');
            }
          );
        } else {

          toast.current.show({
            severity: 'success',
            summary: 'Account created succesfully',
            detail: 'Please proceed to login.',
            life: 4000
          });

          setTimeout(() => {
            navigate('/');
          }, 3000);
        }

      } else {

        toast.current.show({
          severity: 'error',
          summary: 'Registration Failed',
          // responseBody.result.errors.error.message
          detail: response.responseBody.result.errors.error.message,
          life: 4000
        });
        setWaitVerificationLoader(false)

        window.fbq('track', 'Signup/Createprofile', {
          status: "Failed",
          detail: response.responseBody.result.errors.error.message
        });
        console.error('Registration failed:', response.message);
      }
    } catch (error) {

      toast.current.show({
        severity: 'error',
        summary: 'Registration Failed',
        detail: "Signup failed. Please try again in a moment.",
        life: 4000
      })

      window.fbq('track', 'Signup/Createprofile', {
        status: "Failed",
        detail: "Signup failed. Please try again in a moment.",
      });
      setWaitVerificationLoader(false)
      console.error('Error:', error);
    }
  };

  const doneVerifyingId = async (e) => {

    e.preventDefault();


    var uuid = localStorage.getItem("uuid");

    toast.current.show({
      severity: 'info',
      summary: 'ID Verification Pending',
      detail: (<p>
        Live ID verification may take a few minutes. Do not close this page.
      </p>),
      life: 4000
    })


    let _val = 0;
    var message = "";

    setDoneButtonLabel("Refresh");

    setWaitVerificationLoader(true);

    var intervalId;


    intervalId = setInterval(() => {

      _val += Math.floor(Math.random() * 25) + 1;

      if (_val >= 100) {
        _val = 100;
        toast.current.show({ severity: 'info', summary: "Verification Status", detail: message });
        clearInterval(intervalId);

      } else {

        AuthService.getLiveIDVerification(uuid).then((responseLiveIdVefication) => {

          responseLiveIdVefication.responseBody === undefined || responseLiveIdVefication.responseBody === null ? console.debug(responseLiveIdVefication.message) : setFormData(responseLiveIdVefication.responseBody);

          if (responseLiveIdVefication.status === "FAILED") {

            if (responseLiveIdVefication.responseBody == undefined) {

              _val = 101;
              setValue(_val);
              clearInterval(intervalId);
              setWaitVerificationLoader(false)
              message = responseLiveIdVefication.message
              setLiveVerifyIframe(false)

              setErrorMessage(message);

            } else if (responseLiveIdVefication.responseBody.registrationStatus && responseLiveIdVefication.responseBody.registrationStatus.toUpperCase() === "PRE-VERIFICATION") {
              _val -= 2;
              message = responseLiveIdVefication.message
              setErrorMessage(message);
            } else {
              _val = 101;
              setValue(_val);
              clearInterval(intervalId);
              message = responseLiveIdVefication.message
              setWaitVerificationLoader(false)
              setLiveVerifyIframe(false)
              setErrorMessage(message);

              window.fbq('track', 'Signup/Createprofile/Update KYC', {
                status: "KYC Failed",
                detail: message
              });
            }
          } else {
            if (responseLiveIdVefication.responseBody.registrationStatus && ( responseLiveIdVefication.responseBody.registrationStatus.toUpperCase() === "VERIFIED"
              || responseLiveIdVefication.responseBody.verification_status && responseLiveIdVefication.responseBody.verification_status.toUpperCase() === "VERIFIED")) {
              _val = 101;
              setValue(_val);
              clearInterval(intervalId);
              setWaitVerificationLoader(false);
              message = responseLiveIdVefication.message;
              // Show dialog to confirm. 


              setPersonaConfirmationDialog(true);


              window.fbq('track', 'Signup/Createprofile/Update KYC', {
                status: "Success",
                detail: message
              });


            } else {
              _val = 101;
              setValue(_val);
              clearInterval(intervalId);
              setWaitVerificationLoader(false)
              message = responseLiveIdVefication.message
              setErrorMessage(message);

            }
          }
        });
      }
    }, 7000);

  };

  const handleSelectAddress = (value, data) => {
    if (data && data.name) {
      setAddressData({
        ...addressData,
        'addressVal': value,
      });

      setFormData(formData => ({
        ...formData,
        address1: value.address,
      }));
    }
  };

  const handleChangeGender = (value, data) => {
    //console.debug(data)
    if (data && data.name) {
      setgenderData({
        ...genderData,
        'genderVal': value,
      });
      setFormData(formData => ({
        ...formData,
        'gender': value.label
      }));

    }
  };


  const handleChange = (e) => {
    const target = e.target;
    const { name } = target;
    var { value } = target;

    const validationRules = {
      password: {
        hasNumber: /\d/.test(value),
        hasUpperCase: /[A-Z]/.test(value),
        hasMinimumLength: value.length >= 8
      },
      verifyPassword: {
        mustMatch: value === formData.password
      },
      email: {
        isValidEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
      }
    };


    if (name === "email") {
      value = value.trim();
    };

    if (name === "id1Details") {
      value = value.toUpperCase();
    };

    if (name === "postcode") {
      value = value.toUpperCase();
    };

    if (name === "password" || name === "verifyPassword") {
      value = value.replace(/\s/g, "");
    };

    if (!value.trim()) {
      setFormErrors({
        ...formErrors,
        [name]: name + ' is required'
      });
    } else {
      setFormErrors({
        ...formErrors,
        [name]: ''
      });
    }

    setFormData(formData => ({
      ...formData,
      [name]: value,
      [`${name}Validation`]: validationRules[name],
    }));

  };

  const handleFindAddress = async (postalCode) => {
    try {
      const apiKey = 'MUZB4nuIkk6i5XTyv3xVXA42336'

      let data = JSON.stringify({
        "all": true
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://api.getaddress.io/autocomplete/${postalCode}?api-key=${apiKey}`,
        headers: {
          'Content-Type': 'application/json',
          'Cookie': 'TiPMix=22.905410387410008; x-ms-routing-name=self'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          var addressSuggestions = [];

          if (response.data.suggestions.length > 0) {
            response.data.suggestions.forEach(element => {
              element.label = element.address
              addressSuggestions.push(element);

            });
            setAddressData({
              ...addressData,
              'addressOptions': addressSuggestions
            });

          } else {
            setShowAddressses(false);
          }


        })
        .catch((error) => {
          setShowAddressses(false);
          console.error(error);
        });


    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  const handleChangeIdType = (value, data) => {
    if (data && data.name) {
      setIdTypeData({
        ...idTypeData,
        'idTypeVal': value,
      });

      setFormData(formData => ({
        ...formData,
        'id1Type': value.value

      }));

      if (value.value !== 'national_id') {
        setFormData(formData => ({
          ...formData,
          id1Expiry: currentDate,
        }));
      } else {
        setFormData(formData => ({
          ...formData,
          id1Expiry: id1ExpiryDefault,
        }));
      }
    }

  };

  const handleChangeCountry = (value, data) => {
    //console.debug(value)

    if (data && data.name) {
      setCountryData({
        ...countryData,
        'countryVal': value,
      });
      setFormData(formData => ({
        ...formData,
        'countryOfBirth': value.label,
        'nationality': value.value,
        'placeOfBirth': value.label,
      }));
    }
  };

  const handleChangePhone = (value, data) => {

    if (data && data.name) {

      if (!value.trim()) {
        setFormErrors({
          ...formErrors,
          mobile: 'Mobile number is required'
        });
      } else {
        setFormErrors({
          ...formErrors,
          mobile: ''
        });
      }


      setFormData(formData => ({
        ...formData,
        'mobile': "+" + value,
      }));
    }
  };

  return (
    <React.Fragment>
      <div className=" mt-4 pb-4 home-2 bg-home d-flex bg-light" id="home" style={{ width: 'auto' }}>
        <Toast ref={toast} />
        <Container>

          <Dialog header="Verification Documents requirements" visible={kycRequirementsDialog} style={{ width: '600px' }} onHide={() => setKycRequirementsDialog(false)} className='glassmorphic-container-curve'>
            <p className="">
              Before proceeding with the verification process, we kindly ask you to provide one of the following valid forms of identification
              <br />
              <ul>
                <li>Passport</li>
                <li>UK Driver's License</li>
              </ul>

            </p>

            <div className="text-center">
              <Button onClick={(e) => setKycRequirementsDialog(false)} className="btn btn-large bg-primary me-2 ">
                {/* <i className="uil uil-envelope me-1"></i> */}
                Close
              </Button>
            </div>
          </Dialog>

          <Dialog header="Verify Details" visible={personaConfirmation} style={{ width: '600px' }} onHide={() => setPersonaConfirmationDialog(false)} className='reg-move mt-5 contact glassmorphic-container-curve'>
            <div className="contact-box">
              <div className="custom-form mt-4">
                <form method="post" name="myForm" onSubmit={handleSubmitCompleteProfile}>
                  <p id="error-msg" style={{ opacity: 1 }}> </p>
                  <div id="simple-msg"></div>
                  {errorMsg && errorMsg.length > 0 ? <Message className='m-3' severity="error" text={errorMsg} /> : <></>}

                  <Row>
                    {/* firsname */}
                    <Col lg={6}>
                      <div className="form-group">
                        <label className='text-muted'>First name</label>
                        <input
                          name="fname"
                          id="fname"
                          type="text"
                          className="form-control contact-form"
                          placeholder="Enter name"
                          value={formData.fname}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    {/* lastname */}
                    <Col lg={6}>
                      <div className="form-group">
                        <label className='text-muted'>Last name</label>
                        <input
                          name="lname"
                          id="lname"
                          type="text"
                          className="form-control contact-form"
                          placeholder="Enter last name"
                          value={formData.lname}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col>
                    {/* gender */}
                  </Row>

                  <Row className="mt-4">
                    {/* dob */}

                    <Col lg={6}>
                      <div className="form-group">
                        <label className='text-muted mb-2'>DOB</label>
                        <input
                          name="dob"
                          id="dob"
                          type="text"
                          className="form-control contact-form"
                          value={formData.dob}
                          disabled
                        />
                      </div>
                    </Col>

                    {/* placeOfBirth */}
                    <Col lg={6}>
                      <div className="form-group">
                        <label className='text-muted mb-2'>Nationality</label>
                        <input
                          name="nationality"
                          id="nationality"
                          type="text"
                          className="form-control contact-form"
                          value={formData.nationality}
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col lg={7}>
                      <div className="form-group">
                        <label className='text-muted'>{formData.id1Type} Number</label>
                        <input
                          name="id1Details"
                          id="id1Details"
                          type="text"
                          className="form-control contact-form"
                          placeholder={`Enter ${idTypeData.idTypeVal.label} number`}
                          value={formData.id1Details}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </Col >
                    <Col lg={5}>
                      {
                        formData.id1Type !== "national_id" ?
                          <div className="form-group">
                            <label className='text-muted'>{formData.id1Type} Expiry</label>
                            <input
                              name="id1Expiry"
                              id="id1Expiry"
                              type="date"
                              className="form-control contact-form"
                              value={formData.id1Expiry}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          : <></>
                      }
                    </Col>
                  </Row>



                  <Row className='mt-4 mb-2'>
                    {/* email */}


                    <Col lg={6}>
                      <div className="form-group">
                        <label className='text-muted'>Email* </label>
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control contact-form"
                          placeholder=""
                          value={formData.email}
                          onChange={handleChange}
                          autoComplete="off"
                          disabled
                        />

                      </div>
                    </Col>

                    {/* phnenumber */}
                    <Col lg={6}>
                      <div className="form-group">
                        <label className='text-muted' htmlFor="number">
                          Phone Number*
                        </label>
                        <PhoneInput
                          name="mobile"
                          country={"gb"}
                          value={formData.mobile}
                          disabled
                          inputStyle={{
                            height: "55px",
                            width: "100%"
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className='mt-3'>
                    <Col lg={4}>
                      <div className="form-group">
                        <label className='text-muted'>Postcode*</label>
                        <input
                          name="postcode"
                          id="postcode"
                          type="text"
                          disabled
                          className="form-control contact-form"
                          placeholder="Postcode"
                          value={formData.postcode}
                          onChange={handleChange}
                          required
                        />
                        {formErrors.postcode && <p className="text-danger invalid-feedback">{formErrors.postcode}</p>}
                      </div>
                    </Col>
                    <Col lg={8}>
                      <div className="form-group">
                        <label className='text-muted'>Full Address *</label>
                        <input
                          name="address1"
                          id="address1"
                          type="text"
                          disabled
                          className="form-control contact-form"
                          placeholder="Enter full address"
                          value={formData.address1}
                          onChange={handleChange}
                          required
                          style={{ fontSize: "15px" }}
                        />
                        {formErrors.address1 && <p className="text-danger invalid-feedback">{formErrors.address1}</p>}
                      </div>
                    </Col>

                  </Row>

                  <Row className="justify-content-center mt-4">
                    <div className="col-lg-6">
                      <div className="text-center">
                        <Button onClick={(e) => confirmPersonaDetails(e)} className="btn btn-large bg-primary me-2 ">
                          {/* <i className="uil uil-envelope me-1"></i> */}
                          Confirm Details
                        </Button>
                      </div>
                    </div>
                  </Row>
                </form>
              </div>
            </div>
          </Dialog>

          <Row className="align-items-center justify-content-between">

            <h3 className="home-title"><span style={{ color: "#2e3664", fontWeight: "400" }}>A Fast & Secure Way to</span><br /><span style={{ color: "#268e9c", fontWeight: "200" }}>Send Money Anywhere</span></h3>
            <div className="home-heading">
              <h5 className="home-title">{displayProgress === 2 ? <> Welcome to the Wiremit experience<br /></> : <>  Complete your Profile!</>}</h5>
              <p className="">We at Wiremit are thrilled to have you onboard.
                Your Needs, Your Savings, Crafted in One Unstoppable Service</p>
            </div>



            <div className="reg-move mt-5 contact glassmorphic-container-curve ">
              <Container className=''>
                {loading ? (viewList[1]) : (
                  <>{
                    displayProgress === 0 ? (

                      <> {!displayLiveVerifyIframe ? (
                        <LoadingButton
                          onClick={handleSubmitCompleteProfile}
                          fullWidth
                          sx={{
                            background: "#278e9c",
                            ":hover": {
                              background: "#278e9c"
                            }
                          }}
                          loading={false}
                          color="primary"
                          variant="contained">Click here to complete profile</LoadingButton>) :
                        <>
                          {
                            !verificationLeg ? (

                              <Row className="align-items-center justify-content-center text-center">
                                <h5 className="mt-4 mb-4" style={{ color: "orange", fontSize:"1em" }}>
                                   DO NOT CLOSE THIS PAGE.  <br/>
                                  <i>You might be redirected to another page.</i>
                                </h5>
                                <div className="col-lg-7 mb-4"> <img src={logoLight} className="logo-light" alt="" width="180" height="200" /></div>

                                {verificationLoader ?
                                  <ProgressBar mode="indeterminate" style={{ height: '4px' }}></ProgressBar> :
                                  <>
                                    {!checkVerificationStatus ?
                                      <div className="col-lg-9 mb-4">
                                        <Link onClick={() => { setCheckVerificationStatus(true); window.open(`${liveVerifyUrl}`, '_blank'); }} className="btn m-1 mt-2"> Click here to verify KYC</Link>
                                      </div> : <></>
                                    }

                                  </>
                                }
                                {/* {
                                  liveVerifyUrl && <>
                                    <BlockUI blocked={loading}>
                                      <iframe title="verification" src={liveVerifyUrl} width={1000} height={500} ></iframe>
                                    </BlockUI>
                                  </>
                                } */}
                                {
                                  checkVerificationStatus ?
                                    <div className="col-lg-7">

                                      <h5 className="mt-4">
                                        <span style={{ fontWeight: "200", fontSize: "large" }}> Click </span> <span style={{ color: "#268e9c", fontWeight: "400", fontSize: "large" }}> {doneButtonLabel} </span> <span style={{ fontWeight: "200", fontSize: "large" }}> if you have completed the ID verification process.</span>
                                      </h5>
                                      <div className="text-center">
                                        <Button onClick={(e) => doneVerifyingId(e)} className="btn btn-large bg-primary me-2 ">
                                          {doneButtonLabel}
                                        </Button>
                                      </div>
                                    </div> : <></>
                                }
                              </Row>
                            ) : <>
                            </>
                          }
                        </>
                      }</>
                    ) : <div className="border-round border-1 surface-border p-4 surface-card">
                      <div className=" text-center my-4">
                        <img width="100px" src={AnimatedSuccessGIF} alt="" />
                        <Message className='m-3' severity="success" text={"Hi " + formData.fname + " thank for your registration, welcome to the wiremit experience"} />
                        <Button onClick={(e) => navigate("/")} className="btn bg-primary me-2 ">
                          <i className="uil uil-user me-1"></i>
                          Login & Send Money
                        </Button>
                      </div>
                    </div>
                  }</>)
                }
              </Container>
            </div>

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RegisterCompleteProfile;
