/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import AOS from "aos";
import "aos/dist/aos.css";
import Cookies from "js-cookie";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { ProgressBar } from "primereact/progressbar";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import BeneficiaryServices from "../../services/beneficiaryServices";
import TransactionService from "../../services/transactionServices";
import "../Home/CalculatorForm.css";
import "./styles.css";
import TitleComponent from "./titleComponent";

AOS.init({
  duration: 1800,
});

const paymentMethodsList = [{ value: "125", label: "Fena Open Banking" }];

const Section = () => {
  const rowClick = true;
  const [selectedBen, setselectedBen] = useState("");
  const formErrors = [];
  const [loading, setLoading] = useState(false);
  const [continueActive, setContinueActive] = useState(false);

  /*
    STEP COMPONENTS
  */

  const SelectBeneficiary = () => {
    const handleSelect = (e) => {
      window.fbq("track", "Payment/Select beneficiary", {
        status: "Success",
        detail: "",
      });
      if (e.value !== null) {
        setselectedBen(e.value);
        setuserPolicyInput({ ...transactionData, selectedBen: e.value });
      }
    };

    return (
      <>
        {beneficiaries.length > 0 ? (
          <>
            <DataTable
              value={beneficiaries}
              selectionMode={rowClick ? null : "radiobutton"}
              selection={selectedBen}
              onSelectionChange={(e) => {
                handleSelect(e);
                setContinueActive(true);
              }}
              dataKey="name"
            >
              <Column
                selectionMode="single"
                headerStyle={{ width: "3rem" }}
              ></Column>
              <Column field="name"></Column>
            </DataTable>
          </>
        ) : (
          <>
            {" "}
            <h6>No beneficiaries found</h6>{" "}
          </>
        )}
        <div className="text-center">
          <Button
            disabled={!continueActive}
            className="btn bg-primary me-2 mt-4"
            size="large"
            label={
              <>
                <strong color="blue">Continue</strong>
              </>
            }
            severity="info"
            rounded
            outlined
            raised
            onClick={moveToNextView}
          />
        </div>
      </>
    );
  };

  const TransactionForm = () => {
    //alert(Cookies.get('rate-GBP-USD'))
    const [currentRate, setCurrentRate] = useState(
      Cookies.get("rate-GBP-USD") || "1"
    );

    const commissionRate = 0; // Commission rate: 0%
    const fixedFee = 2.5; // Fixed fee: 2.50 GBP
    const [selectedCurrencyPair, setSelectedCurrencyPair] = useState("GBPUSD"); // Default currency for receiver
    const [recipientAmount, setRecipientAmount] = useState("0"); // Default amount to receive
    const [shouldRoundAmountToSend, setShouldRoundAmountToSend] =
      useState(false); // Flag to determine rounding for amount to send
    const [shouldRoundRecipientAmount, setShouldRoundRecipientAmount] =
      useState(true); // Flag to determine rounding for recipient amount

    const [fromCurrency, setFromCurrency] = useState("GBP");
    const [toCurrency, setToCurrency] = useState("USD");
    const [toCountry, setToCountry] = useState({
      value: "947",
      label: "Zimbabwe",
      countryCode: "ZW",
    });
    const [sourceOfFunds, setSourceOfFunds] = useState([]);
    const [purposes, setPurpose] = useState([]);
    const [amountToSend, setAmountToSend] = useState(100);
    const [remitterPay, setRemitterPay] = useState(0);
    const [receiveAmount, setReceiveAmount] = useState(24);
    const [rateLoading, setRateLoading] = useState(false);
    const [amountLoading, setAmountLoading] = useState(false);
    const [reverseCal, setReverseCal] = useState(false);
    const [formData, setFormData] = useState({
      purpose: "",
      amount: amountToSend,
      source_of_income: "Business",
      payment_method: paymentMethodsList[0].value,
      source_currency: fromCurrency,
      destination_currency: toCurrency,
      amount_type: "SOURCE",
      trans_type: "Cash Collection",
      collection_point_name: "Harare",
      beneficiary_id:
        transactionData.selectedBen &&
        transactionData.selectedBen.beneficiary_id,
      username: localStorage.getItem("username"),
      sessionToken: localStorage.getItem("sessionToken"),
      promotion_code: "",
    });
    const [output, setOutput] = useState(0);
    const [totalCharges, setTotalCharges] = useState(0);
    const [displayFees, setDisplayFee] = useState(false);
    const [customPurpose, setCustomPurpose] = useState(false);

    const [receiveOptions, setReceiveOptions] = useState([
      { value: "Cash Pickup", label: "Cash Pickup" },
    ]);

    const [receiveOptionsData, setreceiveOptionsData] = useState({
      receiveOptions: receiveOptions,
      receiveOptionVal: receiveOptions[0],
    });

    const calculateOutput = async () => {
      try {
        if (!reverseCal) {
          setRateLoading(true);
        }

        const response = await TransactionService.getRates(
          toCountry.label,
          fromCurrency,
          toCurrency,
          amountToSend,
          "SOURCE"
        );
        const CurrencyRate = response.body.result.rate;
        if (CurrencyRate) {
          const output = response.body.result.destination_amount;

          setReceiveAmount(output);
          setOutput(output);
          setCurrentRate(CurrencyRate);
          setTotalCharges(response.body.result.total_charges);
          setRemitterPay(response.body.result.remitt_pay);
          setRateLoading(false);
          setAmountLoading(false);
        } else {
          setRateLoading(true);
          setAmountLoading(true);
          toast.current.show({
            severity: "warn",
            summary: "Invalid Amount to Send",
            detail: "Amount to Send cannot be less than 1",
            life: 4000,
          });
        }
      } catch (error) {
        console.error(error);
        setRateLoading(false);
        setAmountLoading(false);
      }
    };

    const getUiSetings = async () => {
      try {
        const response = await TransactionService.getUISettings(
          toCountry.value
        );

        const mappedPurposesData =
          response.responseBody.result.purposes.purpose.map((item) => ({
            value: item,
            label: item,
          }));

        const mappedSourcesData =
          response.responseBody.result.source_of_incomes.source_of_income
            .map((item) => ({ value: item, label: item }))
            .sort((a, b) => {
              const labelA = a.label.toUpperCase(); // Convert both labels to uppercase
              const labelB = b.label.toUpperCase();
              if (labelA === "OTHER") return 1;
              if (labelB === "OTHER") return -1;
              return labelA.localeCompare(b.label);
            });

        setPurpose(mappedPurposesData);
        setSourceOfFunds(mappedSourcesData);

        if (
          typeof response.responseBody.result.transfer_types.transfer_type ===
          "string"
        ) {
          setReceiveOptions([
            response.responseBody.result.transfer_types.transfer_type,
          ]);
        } else {
          setReceiveOptions(
            response.responseBody.result.transfer_types.transfer_type
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleAmountChange = (event) => {
      let rawValue = event.target.value;
      const updatedCurrencyPair = fromCurrency + toCurrency; // Construct new currency pair
      setSelectedCurrencyPair(updatedCurrencyPair); // Update selected currency pair

      let value = rawValue.replace(/[^0-9.]/g, "");

      // Enforce maximum limit
      value = Math.min(parseFloat(value), 9999999);

      let parsedValue = parseFloat(value);
     /* if (parsedValue < 0) {
        parsedValue = 1;
      }

      if (isNaN(parsedValue)) {
        parsedValue = 1;
      }*/
      // Update the amountToSend state with the validated value
      setAmountToSend(parsedValue);
      setFormData({
        ...formData,
        amount: parsedValue,
      });

      // Calculate recipient amount
      const exchangeRateWithCommission = currentRate * (1 + commissionRate);
      const calculatedRecipientAmount = (
        parsedValue /
        (1 / exchangeRateWithCommission)
      ).toFixed(2);
      setRecipientAmount(`${calculatedRecipientAmount}`);
    };

    const handleRecipientAmountChange = (event) => {
      let value = event.target.value;
      const updatedCurrencyPair = fromCurrency + toCurrency; // Construct new currency pair
      setSelectedCurrencyPair(updatedCurrencyPair); // Update selected currency pair

      value = value.replace(/[^0-9.]/g, "");

      // Enforce maximum limit
      value = Math.min(parseFloat(value), 9999999);

      let parsedValue = parseFloat(value);
      if (parsedValue < 0) {
        parsedValue = 1;
      }

      if (isNaN(parsedValue)) {
        parsedValue = 1;
      }
      // Update the recipientAmount state with the validated value
      setRecipientAmount(parsedValue);

      // Calculate amount to send
      const exchangeRateWithCommission = currentRate * (1 + commissionRate);
      const calculatedAmountToSend = (
        parsedValue *
        (1 / exchangeRateWithCommission)
      ).toFixed(2);
      setAmountToSend(`${calculatedAmountToSend}`);
      setFormData({
        ...formData,
        amount: calculatedAmountToSend,
      });
    };

    let fee = "0";
    if (amountToSend) {
      // Calculate fee (sum of commission and fixed fee)
      fee = fixedFee.toFixed(2);
    }

    const roundedAmountToSend =
      (shouldRoundAmountToSend
        ? parseFloat(amountToSend).toFixed(0)
        : amountToSend) || 0;
    const roundedRecipientAmount =
      (shouldRoundRecipientAmount
        ? parseFloat(recipientAmount).toFixed(0)
        : recipientAmount) || 0;

    useEffect(() => {
      // Calculate initial values on component mount

      calculateOutput();
      getUiSetings();
      const exchangeRateWithCommission = currentRate * (1 + commissionRate);

      const initialRecipientAmount = (
        parseFloat(amountToSend) * exchangeRateWithCommission
      ).toFixed(2);
      setRecipientAmount(initialRecipientAmount);
    }, []); // Empty dependency array triggers the effect only once on mount

    const onSendAmountEventBlur = () => {
      if (shouldRoundRecipientAmount) {
        const exchangeRateWithCommission = currentRate * (1 + commissionRate);

        const recalculatedAmountToSend = (
          parseFloat(roundedRecipientAmount) *
          (1 / exchangeRateWithCommission)
        ).toFixed(2);
        setAmountToSend(recalculatedAmountToSend);
        setFormData({
          ...formData,
          amount: recalculatedAmountToSend,
        });
        calculateOutput();
      }
    };
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        // Recalculate amount to send if recipientAmount is rounded
        onSendAmountEventBlur();
      }, 3000);

      return () => clearTimeout(timeoutId);
    }, [
      shouldRoundRecipientAmount,
      roundedRecipientAmount,
      roundedAmountToSend,
    ]);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        // Recalculate recipient amount if amountToSend is rounded
        if (shouldRoundAmountToSend) {
          const exchangeRateWithCommission = currentRate * (1 + commissionRate);

          const recalculatedRecipientAmount = (
            parseFloat(roundedAmountToSend) /
            (1 / exchangeRateWithCommission)
          ).toFixed(2);
          setRecipientAmount(recalculatedRecipientAmount);
        }
      }, 3000);

      return () => clearTimeout(timeoutId);
    }, [shouldRoundAmountToSend, roundedAmountToSend]);

    const [paymentMethodListData, setpaymentMethodListData] = useState({
      paymentMethodList: paymentMethodsList,
      paymentMethodOptionVal: null,
    });

    const handleChangeReceiveOption = (value, data) => {
  
      if (data && data.name) {
        setreceiveOptionsData({
          ...receiveOptionsData,
          receiveOptionVal: value,
        });
        setDisplayFee(true);
      }
    };

    const handleChangePaymentmethod = (value, data) => {

      if (data && data.name) {
        setpaymentMethodListData({
          ...paymentMethodListData,
          paymentMethodOptionVal: value,
        });

        setFormData({
          ...formData,
          payment_method: value.value,
        });
        setDisplayFee(true);
      }
    };

    const handleChangePurpose = (value, data) => {
      //console.debug(data);
      if (data && data.name) {
        if (value.value === "Other") {
          setFormData({
            ...formData,
            purpose: "",
          });
          setCustomPurpose(true);
        } else {
          setCustomPurpose(false);
          setFormData({
            ...formData,
            purpose: value.value,
          });
        }

        setDisplayFee(true);
      }
    };

    const handleChangeSource = (value, data) => {
      //console.debug(data);
      if (data && data.name) {
        setFormData({
          ...formData,
          source_of_income: value.value,
        });
        setDisplayFee(true);
      }
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setDisplayFee(true);
    };

    const handleSubmit = async (e) => {
      if (formErrors && formErrors.length !== 0) {
        return "Validation failed";
      }
      setLoading(true);
      try {
        const response = await TransactionService.initiateTransaction(formData);

        localStorage.setItem("promocode", formData.promotion_code)
        //console.debug(response);
        setLoading(false);
        if (response.status === "SUCCESS") {
          window.fbq("track", "Initiate Payment", {
            status: "Success",
            detail: "Transaction creation successful",
          });
          //console.debug("Transaction creation successful");
          setuserPolicyInput({
            ...transactionData,
            transactionDetails: formData,
            confirmDetails: response,
          });
          moveToNextView();
        } else {
          window.fbq("track", "Initiate Payment", {
            status: "Failed",
            detail: response.message,
          });
          toast.current.show({
            severity: "error",
            summary: response.message,
            detail: response.message,
            life: 4000,
          });
          console.error("Payment failed:", response.message);
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Login Failed",
          detail:
            "Oops, We failed to communicate with our servers, Kindly check your internet connection" +
            error,
          life: 4000,
        });
        console.error("Error:", error);
      }
    };

    return (
      <>
        <div className="align-items-center justify-content-center">
          <div className="contact-box ">
            <div className="custom-form">
              <p id="error-msg" style={{ opacity: 10 }}>
                {" "}
              </p>
              <div id="simple-msg"></div>
              <form method="post" name="myForm">
                <p id="error-msg" style={{ opacity: 1 }}>
                  {" "}
                </p>
                <div id="simple-msg"></div>

                <div className="mt-2 mb-5 rounded-container calculator-width-responsive">
                  <div>
                    <div
                      className="send-money"
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0)",
                        borderTopLeftRadius: "16px",
                        borderTopRightRadius: "16px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          borderTopLeftRadius: "16px",
                          borderTopRightRadius: "16px",
                          borderBottomRightRadius: "16px",
                          borderBottomLeftRadius: "16px",
                        }}
                      >
                        <div
                          style={{
                            background:
                              "linear-gradient(90deg, #345d92 0%, #1c2452 100%)",
                            padding: "10px",
                            borderTopLeftRadius: "16px",
                            borderTopRightRadius: "16px",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "0.8rem",
                              marginBottom: "8px",
                              fontWeight: "bold",
                            }}
                          >
                            Exchange Rate
                          </div>

                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.3rem",
                            }}
                          >
                            1 {fromCurrency} = {currentRate} {toCurrency}
                          </div>
                        </div>

                        <div style={{ borderRadius: "16px" }}>
                          <div
                            className="currency-area"
                            style={{ padding: "20px", margin: "20px" }}
                          >
                            <div
                              style={{
                                textAlign: "left",
                              }}
                            >
                              You send
                              <input
                                autoComplete="off"
                                id="receive-field-container-amount"
                                placeholder="-"
                                type="decimal"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                value={`🇬🇧 GBP ${~~roundedAmountToSend}`}
                                onChange={handleAmountChange}
                                //onBlur={onSendAmountEventBlur}
                              />
                            </div>
                          </div>

                          <div
                            className="currency-area"
                            style={{ padding: "20px", margin: "20px" }}
                          >
                            <div
                              style={{
                                textAlign: "left",
                              }}
                            >
                              They get
                              <input
                                autoComplete="off"
                                id="receive-field-container-amount"
                                placeholder="-"
                                type="decimal"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                value={`🇿🇼 USD ${~~roundedRecipientAmount}`}
                                onChange={handleRecipientAmountChange}
                              />
                            </div>
                          </div>

                          <div style={{ padding: "0px", margin: "20px" }}>
                            <label
                              className="mb-2"
                              style={{
                                textAlign: "left",
                                fontWeight: "100px",
                                fontSize: "0.9em",
                              }}
                            >
                              Source of Funds
                            </label>
                            <div className="">
                              <Select
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    border: "none", // Set border to none
                                  }),
                                }}
                                className="form-control contact-form"
                                name="sourceOfFundsDataVal"
                                isSearchable={true}
                                options={sourceOfFunds}
                                // value={sourceOfFunds.value}
                                value={{
                                  value: formData.source_of_income,
                                  label: formData.source_of_income,
                                }}
                                onChange={handleChangeSource}
                              />
                            </div>
                          </div>
                          <div style={{ padding: "0px", margin: "20px" }}>
                            <label
                              className="mb-2"
                              style={{
                                textAlign: "left",
                                fontWeight: "100px",
                                fontSize: "0.9em",
                              }}
                            >
                              Purpose of Funds
                            </label>
                            <div className="form-group">
                              <Select
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    border: "none", // Set border to none
                                  }),
                                }}
                                className="form-control contact-form"
                                name="purposeDataVal"
                                isSearchable={true}
                                options={purposes}
                                value={purposes.value}
                                onChange={handleChangePurpose}
                              />
                            </div>
                            {customPurpose ? (
                              <div className="form-group">
                                <input
                                  name="purpose"
                                  id="purpose"
                                  type="text"
                                  value={formData.purpose}
                                  onChange={handleChange}
                                  required
                                  className="mt-3 form-control contact-form input-custom-style"
                                  placeholder="Please specify purpose"
                                />
                                {formErrors.purpose && (
                                  <p className="text-danger">
                                    {formErrors.purpose}
                                  </p>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div style={{ padding: "0px", margin: "20px" }}>
                            <label
                              className="mb-2"
                              style={{
                                textAlign: "left",
                                fontWeight: "100px",
                                fontSize: "0.9em",
                              }}
                            >
                              Select Payment Method
                            </label>
                            <div className="form-group">
                              <Select
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    border: "none", // Set border to none
                                  }),
                                }}
                                className="form-control contact-form"
                                name="paymentMethodOptionVal"
                                isSearchable={true}
                                options={
                                  paymentMethodListData.paymentMethodList
                                }
                                // value={paymentMethodListData.value}
                                value={{
                                  value: paymentMethodsList[0].value,
                                  label: paymentMethodsList[0].label,
                                }}
                                onChange={handleChangePaymentmethod}
                              />
                            </div>
                          </div>

                          <div style={{ padding: "0px", margin: "20px" }}>
                            <label
                              className="mb-2"
                              style={{
                                textAlign: "left",
                                fontWeight: "100px",
                                fontSize: "0.9em",
                              }}
                            >
                              Receive Method
                            </label>
                            <div className="form-group">
                              <Select
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    border: "none", // Set border to none
                                  }),
                                }}
                                className="form-control contact-form"
                                name="receiveOptionVal"
                                isSearchable={true}
                                options={receiveOptionsData.receiveOptions}
                                value={receiveOptionsData.receiveOptionVal}
                                onChange={handleChangeReceiveOption}
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              padding: "0px",
                              margin: "20px",
                              marginBottom: "35px",
                            }}
                          >
                            <label
                              className="mb-2"
                              style={{
                                textAlign: "left",
                                fontWeight: "100px",
                                fontSize: "0.9em",
                              }}
                            >
                              Promotion Code
                            </label>
                            <div className="form-group">
                              <input
                                name="promotion_code"
                                id="promotion_code"
                                type="text"
                                value={formData.promotion_code}
                                onChange={handleChange}
                                className="mt-3 form-control contact-form input-custom-style"
                                placeholder="Enter promo code"
                              />
                            </div>
                          </div>
                          <hr />
                          <div
                            className="calculation"
                            style={{
                              padding: "0px 0px 5px 5px",
                              margin: "20px",
                            }}
                          >
                            <div>
                              <div className="mb-1">
                                <div className="con-title">Our Fee</div>
                                <div className="con-value">
                                  {parseFloat(totalCharges).toFixed(2)}{" "}
                                  {fromCurrency}
                                </div>
                              </div>
                              <div className="mb-1">
                                <div className="con-title">Total to Pay </div>
                                <div className="con-value">
                                  {parseFloat(remitterPay).toFixed(2)}{" "}
                                  {fromCurrency}
                                </div>
                              </div>
                              <div className="mb-1">
                                <div className="con-title">Transfer Time </div>
                                <div
                                  className="con-value"
                                  style={{
                                    color: "green",
                                    fontStyle: "italic",
                                  }}
                                >
                                  Instant
                                </div>
                              </div>
                              <div className="mb-1">
                                <div className="con-title">
                                  Collection Points{" "}
                                </div>
                                <div
                                  className="con-value"
                                  style={{
                                    color: "green",
                                    fontSize: "14px",
                                  }}
                                >
                                  Wiremit and Clickacash Branches
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="btn-area mt-20"
                            style={{ margin: "5%" }}
                          >
                            {!rateLoading && !amountLoading ? (
                              <button
                                className="cmn-btn w-100 shimmer-button"
                                onClick={handleSubmit}
                              >
                                Pay{" "}
                                {~~((
                                  parseFloat(roundedAmountToSend) +
                                  parseFloat(fee)
                                ).toFixed(2))}{" "}
                                GBP
                              </button>
                            ) : (
                              <button
                                className="cmn-btn w-100 shimmer-button"
                                disabled // Disables the button
                              >
                                Pay{" "}
                                {(
                                  parseFloat(roundedAmountToSend) +
                                  parseFloat(fee)
                                ).toFixed(2)}{" "}
                                GBP
                              </button>
                            )}

                            <br />
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  };

  const ConfirmationSection = () => {
    const handleSubmit = async (e) => {
      let user = JSON.parse(localStorage.getItem("user"));
      const formData = {
        username: localStorage.getItem("username"),
        sessionToken: localStorage.getItem("sessionToken"),
        senderName: user.firstname,
        senderEmail: user.email,
        senderPhoneNumber: user.mobile,
        trans_session_id:
          transactionData.confirmDetails &&
          transactionData.confirmDetails.responseBody
            ? transactionData.confirmDetails.responseBody.result.transaction
                .trans_session_id
            : "Not provided",
      };

      if (formErrors && formErrors.length !== 0) {
        return "Validation failed";
      }
      setLoading(true);
      try {
        const response = await TransactionService.confirmTransaction(formData);
        //console.debug(response);
        localStorage.setItem(
          "transactionProcess",
          JSON.stringify(
            response.responseBody.transactionConfirmation.result.transaction
          )
        );
        setLoading(false);
        if (response.status === "SUCCESS") {
          window.fbq("track", "Transaction Confirmed", {
            status: "Sucess",
            detail: response.message,
          });
          //console.debug("Transaction creation successful");
          setuserPolicyInput({
            ...transactionData,
            finalResponse: response.responseBody,
          });
          moveToNextView();
        } else {
          window.fbq("track", "Transaction Confirmed", {
            status: "Failed",
            detail: response.message,
          });
          toast.current.show({
            severity: "error",
            summary: "Login Failed",
            detail: response.message,
            life: 4000,
          });
          console.error("Registration failed:", response.message);
        }
      } catch (error) {
        window.fbq("track", "Transaction Confirmed", {
          status: "Failed",
          detail: error,
        });
        toast.current.show({
          severity: "error",
          summary: "Login Failed",
          detail:
            "Oops, We failed to communicate with our servers, Kindly check your internet connection" +
            error,
          life: 4000,
        });
        console.error("Error:", error);
      }
    };

    return (
      <>
        <Row className="align-items-center justify-content-center">
          {transactionData ? (
            <>
              <ul className="mt-1">
                <li className="row">
                  <div className="col-md-4">
                    <h6>Selected Receiving Beneficiary</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <p>
                      {transactionData.selectedBen
                        ? transactionData.selectedBen.name
                        : "Not provided"}
                    </p>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4">
                    <h6>Amount</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <p>
                      {transactionData.transactionDetails
                        ? transactionData.transactionDetails.source_currency +
                          " " +
                          parseFloat(
                            transactionData.transactionDetails.amount
                          ).toFixed(2)
                        : "Not provided"}
                    </p>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4">
                    <h6>Source currency | Destination currency</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <p>
                      {transactionData.transactionDetails ? (
                        <>
                          {transactionData.transactionDetails.source_currency} |{" "}
                          {
                            transactionData.transactionDetails
                              .destination_currency
                          }
                        </>
                      ) : (
                        "Not provided"
                      )}
                    </p>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4">
                    <h6>Purpose of funds</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <p>
                      {transactionData.transactionDetails
                        ? transactionData.transactionDetails.purpose
                        : "Not provided"}
                    </p>
                  </div>
                </li>

                <li className="row">
                  <div className="col-md-4">
                    <h6>Source of funds</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <p>
                      {transactionData.transactionDetails
                        ? transactionData.transactionDetails.source_of_income
                        : "Not provided"}
                    </p>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4">
                    <h6>Charges</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <p>
                      {transactionData.confirmDetails &&
                      transactionData.confirmDetails.responseBody
                        ? transactionData.transactionDetails.source_currency +
                          " " +
                          parseFloat(
                            transactionData.confirmDetails.responseBody.result
                              .transaction.total_charges
                          ).toFixed(2)
                        : "Not provided"}
                    </p>
                  </div>
                </li>
                <li className="row">
                  <div className="col-md-4">
                    <h6>Total Amount</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <p>
                      {transactionData.confirmDetails &&
                      transactionData.confirmDetails.responseBody
                        ? transactionData.transactionDetails.source_currency +
                          " " +
                          transactionData.confirmDetails.responseBody.result
                            .transaction.remitter_pay
                        : "Not provided"}
                    </p>
                  </div>
                </li>

                <li className="row">
                  <div className="col-md-4">
                    <h6>Total Receivable Amount</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <p>
                      {transactionData.confirmDetails &&
                      transactionData.confirmDetails.responseBody
                        ? transactionData.transactionDetails
                            .destination_currency +
                          " " +
                          transactionData.confirmDetails.responseBody.result
                            .transaction.receive_amount
                        : "Not provided"}
                    </p>
                  </div>
                </li>

                <li className="row">
                  <div className="col-md-4">
                    <h6>Promotional Code</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <p>
                      {localStorage.getItem("promocode")}
                      <br/> 
                    </p>
                    {transactionData.confirmDetails.responseBody.result.transaction.promotion_names}
                  </div>
                </li>
              </ul>
            </>
          ) : (
            <> No informaiton to display</>
          )}
        </Row>
        <div className="text-center">
          <Button
            className="btn   me-2 mt-4"
            size="large"
            label={
              <>
                <strong color="white">Cancel Transaction</strong>
              </>
            }
            severity="info"
            rounded
            outlined
            raised
            onClick={(e) => {
              window.location.reload();
            }}
          />
          <Button
            className="btn bg-primary me-2 mt-4"
            size="large"
            label={
              <>
                <strong color="blue">Confirm Transaction</strong>
              </>
            }
            severity="info"
            rounded
            outlined
            raised
            onClick={handleSubmit}
          />
        </div>
      </>
    );
  };

  const PaymentSection = () => {
    const [value, setValue] = useState(0);

    const toast = useRef(null);
    const interval = useRef(null);

    var transactionRef = JSON.parse(
      localStorage.getItem("transactionProcess")
    ).trans_ref;

    useEffect(() => {
      let _val = value;
      var message = "TRANSACTION PENDING";

      interval.current = setInterval(() => {
        _val += Math.floor(Math.random() * 10) + 1;

        if (_val >= 100) {
          _val = 100;
          toast.current.show({
            severity: "info",
            summary: "Transaction Status",
            detail: message,
          });
          clearInterval(interval.current);
        } else {
          TransactionService.checkTransactionStatus(transactionRef).then(
            (response) => {
              window.fbq("track", "Check Transaction Status", {
                status: "Success",
                detail: "",
              });

              let transactionStatus = "PENDING_CLEARANCE";
              if (response !== undefined) {
                transactionStatus = response.status;
              }

              if (
                transactionStatus === "PENDING_CLEARANCE" ||
                response.status === undefined ||
                transactionStatus === undefined ||
                transactionStatus === ""
              ) {
                _val -= 2;
                setValue(_val);
              } else {
                _val = 100;
                setValue(_val);
                message =
                  response.message !== undefined
                    ? transactionStatus + ". " + response.message
                    : transactionStatus + ".";
              }
            }
          );
        }
      }, 2000);

      return () => {
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = null;
        }
      };
    }, []);

    const handleChange = (e, fieldName) => {
      //console.debug(fieldName);
      if (e.value != null) {
        setuserPolicyInput({
          ...transactionData,
          [fieldName]: e.value,
        });
      }
    };

    return (
      <>
        <Row className="align-items-center justify-content-center">
          {transactionData.finalResponse.payment &&
          transactionData.finalResponse.payment.result ? (
            <>
              <div className="mt-1 inline-flex align-items-center">
                <Toast ref={toast}></Toast>
                <ProgressBar
                  value={"Checking transaction status: " + value}
                ></ProgressBar>
              </div>

              <div className="text-center mt-4 mb-4">
                <Divider align="left">
                  <div className="inline-flex align-items-center">
                    <i className="pi pi-user mr-2"></i>
                    <b>Complete your payment via your bank account.</b>
                  </div>
                </Divider>
              </div>
              {/* <iframe style={{ height: "40em" }} class="bdt-lazyload lazy-loaded" data-throttle="300" data-threshold="100" data-live="true" data-src="https://currency.wiremit.money/" data-auto_height="false" allowfullscreen="" src={transactionData.finalResponse.payment.result.link}></iframe> */}
              <div className="ms-auto inline-flex text-center">
                <Link
                  target="_blank"
                  to={transactionData.finalResponse.payment.result.link}
                  className="btn bg-primary inline-flex align-items-center"
                >
                  Click to complete transaction from your bank
                </Link>
              </div>
              <div className="text-center mt-4">
                <Divider align="left">
                  <div className="inline-flex align-items-center">
                    <i className="pi pi-user mr-2"></i>
                    <b>
                      Or Scan QR Code to complete transaction on your banking
                      app.
                    </b>
                  </div>
                </Divider>
                <img
                  src={transactionData.finalResponse.payment.result.qrCodeData}
                  style={{ width: "20%" }}
                  alt=""
                />
              </div>
            </>
          ) : (
            <> No informaiton to display</>
          )}
        </Row>
      </>
    );
  };
  const [activeIndex, setActiveIndex] = useState(0);

  const toast = useRef(null);
  const items = [
    {
      label: "Select Beneficiary",
      index: 0,
    },
    {
      label: "Enter Transaction Details",
      index: 1,
    },
    {
      label: "Confirm Transaction",
      index: 2,
    },
    {
      label: "Make a Secure Payment",
      index: 3,
    },
  ];
  const [beneficiaries, setBeneficiaries] = useState([]);

  useEffect(() => {
    BeneficiaryServices.getBeneficiary().then((data) => {
      try {
        if (
          data.responseBody.result.beneficiaries.beneficiary.length ===
          undefined
        ) {
          //console.debug([data.responseBody.result.beneficiaries.beneficiary]);
          var singleBeneficiary = [
            data.responseBody.result.beneficiaries.beneficiary,
          ];
          setBeneficiaries(singleBeneficiary);
        } else {
          setBeneficiaries(data.responseBody.result.beneficiaries.beneficiary);
        }
      } catch (error) {
        console.error(error);
      }
    });
  }, []);

  const [activeView, setactiveView] = useState(0);
  const [transactionData, setuserPolicyInput] = useState({});
  const viewList = [
    <SelectBeneficiary />,
    <TransactionForm />,
    <ConfirmationSection />,
    <PaymentSection />,
  ];
  const viewListTitles = [
    {
      topHeading: "Fill the send money form",
      middleHeading: "Select Receiving Beneficiary",
    },
    {
      topHeading: "Fill the send money form",
      middleHeading: "Enter Transaction Details",
    },
    {
      topHeading: "Fill the send money form",
      middleHeading: "Confirm Details",
    },
    { topHeading: "", middleHeading: "Make a Secure Payment" },
  ];

  const moveToNextView = () => {
    //console.debug(transactionData);
    setactiveView(activeView + 1);
    if (activeView < viewList.length) setactiveView(activeView + 1);
    setActiveIndex(activeView + 1);
  };

  const moveToPreviousView = () => {
    if (activeView !== 0) setactiveView(activeView - 1);
    setActiveIndex(activeView - 1);
  };

  return (
    <React.Fragment>
      <div className="section " id="home">
        <Container>
          <Row className="align-items-center justify-content-between">
            <div className="">
              <Toast ref={toast}></Toast>
              {/*<Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
               */}
              <Stepper activeStep={activeIndex} alternativeLabel>
                {items.map((item) => (
                  <Step
                    key={item.label}
                    onClick={(e) => {
                      if (activeIndex > item.index) setActiveIndex(item.index);
                    }}
                    sx={{
                      "& .MuiStepLabel-root .Mui-completed": {
                        color: "#4cb3c3b2", // circle color (COMPLETED)
                      },
                      "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                        {
                          color: "grey.500", // Just text label (COMPLETED)
                        },
                      "& .MuiStepLabel-root .Mui-active": {
                        color: "#e67e22", // circle color (ACTIVE)
                      },
                      "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                        {
                          color: "common.black", // Just text label (ACTIVE)
                        },
                      "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                        fill: "white", // circle's number (ACTIVE)
                      },
                    }}
                  >
                    <StepLabel>{item.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {React.cloneElement(<TitleComponent />, {
                titles: viewListTitles[activeIndex],
                moveToPreviousView: moveToPreviousView,
                activeView: activeIndex,
                transactionData: transactionData,
              })}
              {loading ? (
                <div className="border-round border-1 surface-border p-4 surface-card">
                  <div className="flex mb-3">
                    <Skeleton
                      shape="circle"
                      size="4rem"
                      className="mr-2"
                    ></Skeleton>
                    <div>
                      <Skeleton width="10rem" className="mb-2"></Skeleton>
                      <Skeleton width="5rem" className="mb-2"></Skeleton>
                      <Skeleton height=".5rem"></Skeleton>
                    </div>
                  </div>
                  <Skeleton width="100%" height="150px"></Skeleton>
                  <div className="flex justify-content-between mt-3">
                    <Skeleton width="4rem" height="2rem"></Skeleton>
                    <Skeleton width="4rem" height="2rem"></Skeleton>
                  </div>
                </div>
              ) : (
                <>
                  {React.cloneElement(viewList[activeIndex], {
                    titles: viewListTitles[activeIndex],
                    moveToNextView: moveToNextView,
                    setuserPolicyInput: setuserPolicyInput,
                    transactionData: transactionData,
                    toast: toast,
                  })}
                </>
              )}
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Section;
